import { createSlice } from '@reduxjs/toolkit';

// ----------------------------------------------------------------------

const initialState = {
  data : []
};

const slice = createSlice({
  name: 'booking',
  initialState,
  reducers: {
    booking(state, action) {
      const bookings = action.payload;

      state.data = bookings;
    },

  },
});

// Reducer
export default slice.reducer;

// Actions
export const {
  booking,
 
} = slice.actions;
