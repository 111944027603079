import { useMemo } from 'react';
// routes
import { paths } from 'src/routes/paths';
// locales
import { useLocales } from 'src/locales';
// components
import Label from 'src/components/label';
import Iconify from 'src/components/iconify';
import SvgColor from 'src/components/svg-color';
import { useAuthContext } from 'src/auth/hooks';

// ----------------------------------------------------------------------

const icon = (name) => (
  <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />
  // OR
  // <Iconify icon="fluent:mail-24-filled" />
  // https://icon-sets.iconify.design/solar/
  // https://www.streamlinehq.com/icons
);

const ICONS = {
  job: icon('ic_job'),
  blog: icon('ic_blog'),
  chat: icon('ic_chat'),
  mail: icon('ic_mail'),
  user: icon('ic_user'),
  file: icon('ic_file'),
  lock: icon('ic_lock'),
  tour: icon('ic_tour'),
  order: icon('ic_order'),
  label: icon('ic_label'),
  blank: icon('ic_blank'),
  kanban: icon('ic_kanban'),
  folder: icon('ic_folder'),
  banking: icon('ic_banking'),
  booking: icon('ic_booking'),
  invoice: icon('ic_invoice'),
  product: icon('ic_product'),
  calendar: icon('ic_calendar'),
  disabled: icon('ic_disabled'),
  external: icon('ic_external'),
  menuItem: icon('ic_menu_item'),
  ecommerce: icon('ic_ecommerce'),
  analytics: icon('ic_analytics'),
  dashboard: icon('ic_dashboard'),
  Plan : icon('plan'),
  Enquiry : icon('enquiry1'),
  Parking : icon('ic_parking1'),
  parkingLocation : icon('ic_parking-sign'),
  parkings : icon('parking'),
  bookings : icon('booking'),
  shuttle : icon('shuttle'),
  locationType : icon('location'),
  rider : icon('driver')
};

// ----------------------------------------------------------------------

export function useNavData() {
  const { t } = useLocales();

  const { user } = useAuthContext();
  let data = [];

  if (user && user.permissions.includes('admin')) {
    data = [
      // OVERVIEW
      // ----------------------------------------------------------------------
      {
        subheader: t('Dashboard'),
        items: [
          {
            title: 'Dashboard',
            path: paths.dashboard.root,
            icon: ICONS.dashboard,
          },
          {
            title: t('user'),
            path: paths.dashboard.user.root,
            icon: ICONS.user,
            children: [
              { title: t('list'), path: paths.dashboard.user.list },
              { title: t('create'), path: paths.dashboard.user.new },
            ],
          },
          {
            title: t('Lot Owner Plan'),
            path: paths.dashboard.lotOwnerPlan.root,
            icon: ICONS.Plan,
            children: [
              { title: t('list'), path: paths.dashboard.lotOwnerPlan.list },
              { title: t('create'), path: paths.dashboard.lotOwnerPlan.new },
            ],
          },
          {
            title: t('Lot Owner Enquiry'),
            path: paths.dashboard.lotOwnerEnquiry.root,
            icon: ICONS.Enquiry,
            children: [
              { title: t('list'), path: paths.dashboard.lotOwnerEnquiry.list},
            ],
          },
          {
            title: t('Parking Facility'),
            path: paths.dashboard.parkingFacility.root,
            icon: ICONS.Parking,
            children: [
              { title: t('list'), path: paths.dashboard.parkingFacility.list},
              { title: t('create'), path: paths.dashboard.parkingFacility.new },
            ],
          },
          {
            title: t('Parking Location Type'),
            path: paths.dashboard.parkingLocationType.root,
            icon: ICONS.locationType,
            children: [
              { title: t('list'), path: paths.dashboard.parkingLocationType.list},
              { title: t('create'), path: paths.dashboard.parkingLocationType.new },
            ],
          },
          {
            title: t('Parking Locations'),
            path: paths.dashboard.parkingLocations.root,
            icon: ICONS.parkingLocation,
            children: [
              { title: t('list'), path: paths.dashboard.parkingLocations.list},
              { title: t('create'), path: paths.dashboard.parkingLocations.new },
            ],
          }, 
          {
            title: t('Parkings'),
            path: paths.dashboard.parkings.root,
            icon: ICONS.parkings,
            children: [
              { title: t('list'), path:paths.dashboard.parkings.list},
              { title: t('create'), path:paths.dashboard.parkings.new},
            ]
          },
          {
            title: t('Destination Pickup'),
            path: paths.dashboard.destinationPickups.root,
            icon: ICONS.parkings,
            children: [
              { title: t('list'), path:paths.dashboard.destinationPickups.list},
              { title: t('create'), path:paths.dashboard.destinationPickups.new},
            ]
          },
          {
            title: t('Bookings'),
            path: paths.dashboard.bookings.root,
            icon: ICONS.bookings,
            children: [
              { title: t('list'), path:paths.dashboard.bookings.list}
            ]
          },
          {
            title: t('Shuttle'),
            path: paths.dashboard.shuttle.root,
            icon: ICONS.shuttle,
            children: [
              { title: t('list'), path:paths.dashboard.shuttle.list},
              { title: t('create'), path:paths.dashboard.shuttle.new},
            ]
          },
          {
            title: t('Rider'),
            path: paths.dashboard.rider.root,
            icon: ICONS.rider,
            children: [
              { title: t('list'), path:paths.dashboard.rider.list},
              { title: t('create'), path:paths.dashboard.rider.new},
            ]
          }          
        ],
      },
    ];
  }

  if (user && user.permissions.includes('lot_owner')) {
    data = [
      // OVERVIEW
      // ----------------------------------------------------------------------
      {
        subheader: t('Dashboard'),
        items: [
          {
            title: 'Dashboard',
            path: paths.dashboard.root,
            icon: ICONS.dashboard,
          },
          {
            title: t('Parking Locations'),
            path: paths.dashboard.parkingLocations.root,
            icon: ICONS.parkingLocation,
            children: [
              { title: t('list'), path: paths.dashboard.ownerParkingLocations.list},
              // { title: t('create'), path: paths.dashboard.ownerParkingLocations.new },
            ],
          }, 
          {
            title: t('Parkings'),
            path: paths.dashboard.parkings.root,
            icon: ICONS.parkings,
            children: [
              { title: t('list'), path:paths.dashboard.ownerParkings.list},
              { title: t('create'), path:paths.dashboard.ownerParkings.new},
            ]
          },
          {
            title: t('Destination Pickup'),
            path: paths.dashboard.parkings.root,
            icon: ICONS.parkings,
            children: [
              { title: t('list'), path:paths.dashboard.ownerParkings.list},
              { title: t('create'), path:paths.dashboard.ownerParkings.new},
            ]
          },
          {
            title: t('Bookings'),
            path: paths.dashboard.ownerBookings.root,
            icon: ICONS.bookings,
            children: [
              { title: t('list'), path:paths.dashboard.ownerBookings.list}
            ]
          },  
          {
            title: t('Shuttle'),
            path: paths.dashboard.lotOwnerShuttle.root,
            icon: ICONS.bookings,
            children: [
              { title: t('list'), path:paths.dashboard.lotOwnerShuttle.list},
              { title: t('create'), path:paths.dashboard.lotOwnerShuttle.new},
            ]
          },
          {
            title: t('Rider'),
            path: paths.dashboard.lotOwnerRider.root,
            icon: ICONS.shuttle,
            children: [
              { title: t('list'), path:paths.dashboard.lotOwnerRider.list},
              { title: t('create'), path:paths.dashboard.lotOwnerRider.new},
            ]
          }    
        ],
      },
    ];
  }

  return data;
}
