import PropTypes from 'prop-types';
// @mui
import Button from '@mui/material/Button';
// routes
import { RouterLink } from 'src/routes/components';
// config
import { PATH_AFTER_LOGIN } from 'src/config-global';

// ----------------------------------------------------------------------

export default function NewLotOwnerButton({ sx }) {
  return (
    <Button
      component={RouterLink}
      to='/CombinedForm'
      variant="text"
      sx={{ mr: 1, color: '#001083', textDecoration: 'underline', ...sx }}
    >
      New lot owner
    </Button>
  );
}

NewLotOwnerButton.propTypes = {
  sx: PropTypes.object,
};
